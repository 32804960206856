import { useState, useEffect } from "react";
import { buscarModulosAtivos } from "../../services/modulos";
import { criptografarDado, descriptografarDado } from "../../helpers";
import { listaModulo, modulosContratados } from "../../interfaces/modulos";

const useModulosContratados = () => {

    const [sistemasContratos, setSistemasContratados] = useState<listaModulo[]>([])

    useEffect(() => {

        const carregarModulos = async () => {

            try {

                const modulosSalvos = document.cookie.indexOf('modulosGesttor')

                if (modulosSalvos < 0) {
                    const resposta = localStorage.getItem("organizacaoGesttor")

                    if (!resposta) return

                        const idOrganizacaoCript = criptografarDado(resposta)

                        const modulosContratados = await buscarModulosAtivos(idOrganizacaoCript)

                        const data = new Date()

                        const validadeToken = new Date(data.getTime() + 60 * 60 * 4 * 1000)

                        const modulos = criptografarDado(JSON.stringify(modulosContratados.data))

                        document.cookie = `modulosGesttor=${modulos}; expires=true ${validadeToken.toUTCString()}`

                        return setSistemasContratados(modulosContratados.data)

                } else {
                    
                    const modulos = document.cookie.split('; ').find(modulo => modulo.startsWith('modulosGesttor'))?.split('=')[1]
                    
                    const modulosDescript = descriptografarDado(modulos || "")

                    return setSistemasContratados(JSON.parse(modulosDescript))
                }

            } catch (error: any) {

                return alert(error)

            }
        }

        carregarModulos()

    }, [])

    return sistemasContratos

}


const Modulos = (props: modulosContratados) => {

    const modulosOrganizacao = useModulosContratados()

    if (props.modulo.some(modulo => modulosOrganizacao.some(m => m.nome === modulo && m.situacao === 'Ativo'))) {
        return props.children
    }
    return null

}

export default Modulos
