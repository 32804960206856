import { FormEvent, useContext, useEffect, useState } from "react";
import Perfil from "../../middleware/Perfil";
import ModulosContratados from "../../middleware/SistemasContratados"
import FiltrosDefault from "../FiltrosDefault";
import NenhumResultadoEncontrado from "../NenhumResultadoEncontrato";
import LoadingBarra from "../LoadingBarra";
import DivSituacao from "../DivSituacao";
import { ModalEstadoContext } from "../../contexts/ModalEstado";
import './index.css'
import { listarProjetos } from "../../services/projetos";
import { ProjetosListar } from "../../interfaces/projetos";
import avatarDefault from "../../assets/img/avatarDefault.png";
import { toast } from "react-toastify";
import ModalProjeto from "../ModalProjeto";
import { useLocation } from "react-router-dom";
import { HiDocumentSearch } from "react-icons/hi";
import { criptografarDado } from "../../helpers";

function Projetos() {
  const { modalExibir, modal, atualizarDados, modalEstado } = useContext(ModalEstadoContext)
  const { search } = useLocation()

  const paramsUrl = new URLSearchParams(search)
  const meusProjetos = paramsUrl.get('meus-projetos') || ""

  const [nenhumResultado, setNenhumResultado] = useState(false)
  const [situacao, setSituacao] = useState("Ativo");
  const [processando, setProcessando] = useState(false);
  const [listaProjetos, setListaProjetos] = useState<ProjetosListar[]>([]);
  const [maisDados, setMaisDados] = useState(false)

  const [pagina, setPagina] = useState(1);
  const [nomeFantasia, setNomeFantasia] = useState("");

  const [projetoSelecionado, setProjetoSelecionado] = useState<ProjetosListar>();

  const projetosRedirect = (idProjeto: string, grupo: string) => {
    const cookie = document.cookie.split('; ')
    const authCookie = cookie.find(token => token.startsWith('authGesttor'))
    const perfilUsuario = localStorage.getItem('perfilGesttor')
    if (!authCookie) {
      return toast.info("Credenciais não encontrada.")
    }

    if (!perfilUsuario) {
      return toast.info("Perfil Gesttor não encontrado.")
    }

    const token = authCookie.split('=')[1]

    const url = `${process.env.REACT_APP_URL_MICROSERVICO_PROJETO}/${grupo}/${criptografarDado(idProjeto)}/${token}/${criptografarDado(perfilUsuario)}`

    return window.open(`${url}`, "_blank")
  }

  const filtro = async (e: FormEvent) => {
    e.preventDefault()
    setNenhumResultado(false)
    setProcessando(true)
    try {
      const queryParams = {
        pagina,
        nomeFantasia,
        situacao,
        meusProjetos
      }

      const resposta = await listarProjetos(queryParams)
      setListaProjetos(resposta.data)
      setProcessando(false)
      if (resposta.data.length === 0) return setNenhumResultado(true)
    } catch (error) {
      setProcessando(false)
      toast.error("Ocorreu um erro ao tentar filtrar os Comunicados;")
    }
  }

  const scrollInfinito = async (e: any) => {

    const { scrollTop, clientHeight, scrollHeight } = e.target

    const posicaoScroll = (scrollTop / (scrollHeight - clientHeight) * 100)

    if (posicaoScroll >= 90 && maisDados) {
      setPagina(prevStat => prevStat + 1)

      setProcessando(true)

      const params = {
        pagina,
        nomeFantasia,
        situacao,
        meusProjetos
      }

      try {

        const resposta = await listarProjetos(params)

        if (resposta.data.length === 0) {
          setProcessando(false)
          return setMaisDados(false)
        }

        if (resposta.status === 200) {
          setListaProjetos(prevtData => [...prevtData, ...resposta.data])
          setProcessando(false)

          return setMaisDados(false)
        }

      } catch (error) {

        setProcessando(false)
        return toast.error("Falha ao buscar mais Projetos")
      }
    }
  }

  const exibirModalProjeto = (projeto?: ProjetosListar) => {

    modalExibir("ModalProjeto")

    return setProjetoSelecionado(projeto)
  }

  useEffect(() => {

    const listarTodosProjetos = async () => {
      setNenhumResultado(false)
      setProcessando(true)

      try {
        const queryParams = {
          pagina,
          nomeFantasia,
          situacao,
          meusProjetos
        }

        const resposta = await listarProjetos(queryParams)

        if (resposta.data.length === 0) {
          setProcessando(false)
          return setNenhumResultado(true)
        }

        if (resposta.status === 200) {
          setProcessando(false)
          return setListaProjetos(resposta.data)
        }

      } catch (error) {
        setProcessando(false)
        toast.error("Falha ao listar os Projetos")
      }
    }
    listarTodosProjetos()

  }, [atualizarDados, meusProjetos])

  useEffect(() => {

    if (!modalEstado) setProjetoSelecionado(undefined)

  }, [modalEstado])

  return (
    <ModulosContratados modulo={["Projetos"]}>
      <Perfil perfil={['master', 'administrativo', 'atendimento', 'consultor', 'desenvolvedor']}>
        <div id='paginaContainer'>
          {processando && <LoadingBarra />}
          {nenhumResultado && <NenhumResultadoEncontrado />}
          <FiltrosDefault termo={nomeFantasia} situacao={situacao} placeholder='Pesquise pelo Nome Fantasia do Cliente' tipo={"Projeto"} onChangeTermo={(e) => setNomeFantasia(e.target.value)} onChangeSituacao={(e) => setSituacao(e.target.value)} acaoBtn={meusProjetos === "true" ? () => { } : () => { exibirModalProjeto() }} listaSituacao={[{ nome: 'Todos', valor: '' }, { nome: 'Ativo', valor: 'Ativo' }, { nome: 'Concluído', valor: 'Concluído' }, { nome: 'Cancelado', valor: 'Cancelado' }]} genero='masculino' isLoading={processando} maisFiltros={false} acaoMaisFiltro={() => { }} onSubmit={filtro} />

          <section id='fundoContainerPagina' onScroll={scrollInfinito}>
            <table id='tabelaListaContainer'>
              <thead>
                <tr>
                  <th align='left'>Nome Fantasia</th>
                  <th align='left' className='hide-responsive'>Titulo do Projeto</th>
                  <th align='left' className='hide-responsive'>Grupo / Serviço</th>
                  <th align='center' className='hide-responsive'>Membros do Projeto</th>
                  <th align='center'>Situação</th>
                  {!meusProjetos && <th align='center'>Ações</th>}
                </tr>
              </thead>
              <tbody>
                {listaProjetos.map((projeto) => (
                  <tr key={projeto.id}>
                    <td className='primeiraColuna' onClick={() => { setProjetoSelecionado(projeto); meusProjetos === "true" ? projetosRedirect(projeto.id.toString(), projeto.grupo) : exibirModalProjeto(projeto) }}>{projeto.cadastro.nomeFantasia}</td>
                    <td align='left' className='hide-responsive' onClick={() => { setProjetoSelecionado(projeto); meusProjetos === "true" ? projetosRedirect(projeto.id.toString(), projeto.grupo) : exibirModalProjeto(projeto) }}>{projeto.titulo}</td>
                    <td align='left' className='hide-responsive' onClick={() => { setProjetoSelecionado(projeto); meusProjetos === "true" ? projetosRedirect(projeto.id.toString(), projeto.grupo) : exibirModalProjeto(projeto) }}>{projeto.grupo}</td>
                    <td align='center' className='hide-responsive' onClick={() => { setProjetoSelecionado(projeto); meusProjetos === "true" ? projetosRedirect(projeto.id.toString(), projeto.grupo) : exibirModalProjeto(projeto) }}>
                      {projeto.projetosMembros.slice(0, 3).map((membro, index) => (
                        <img src={membro.usuario.avatar || avatarDefault} alt="" className="avatarProjeto" key={index} />
                      ))}
                    </td>
                    <td align='center' onClick={() => { setProjetoSelecionado(projeto); meusProjetos === "true" ? projetosRedirect(projeto.id.toString(), projeto.grupo) : exibirModalProjeto(projeto) }}>
                      <DivSituacao texto={projeto.situacao} cor={projeto.situacao.toLowerCase()} />
                    </td>
                    {!meusProjetos && <td align='center' onClick={() => { projetosRedirect(projeto.id.toString(), projeto.grupo) }}>
                      <HiDocumentSearch className="visualizar-projeto-icone" />
                    </td>}
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
          {modal.nome === "ModalProjeto" && <ModalProjeto projeto={projetoSelecionado} />}
        </div>
      </Perfil>
    </ModulosContratados>)
}

export default Projetos
